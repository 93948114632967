import "./footer.css";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import InfoIcon from "@mui/icons-material/Info";

function Footer() {
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const navigate = useNavigate();
    const [setting, setSetting] = useState(JSON.parse(localStorage.getItem("setting")));
    const [popup, setPopup] = useState(false);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/setting/get`, {}).then((res) => {
            if (setting != res.data.data[0]) {
                setSetting(res.data.data[0]);
                localStorage.setItem("setting", JSON.stringify(res.data.data[0]));
            }
        });
    }, []);
    return (
        <>
            <footer>
                <div className="container">
                    <div className="footer-top">
                        <div>
                            <img alt="" src={require("../../image/logo.png")} style={{ height: "55px", width: "auto", filter: "drop-shadow(2px 4px 6px black)" }} />
                        </div>
                        <div>
                            <h3>GIẤY PHÉP</h3>
                            <img alt="" src="/images/footer-giay.png" style={{ height: "85px", width: "auto" }} />
                        </div>
                        <div>
                            <h3>BẢO VỆ</h3>
                            <img alt="" src="/images/footer-bao.png" style={{ height: "85px", width: "auto" }} />
                        </div>
                    </div>
                    <div className="footer">
                        <div className="marquee game-logo">
                            <div class="marquee-content">
                                <div class="logo-game logo-game-1" />
                                <div class="logo-game logo-game-2" />
                                <div class="logo-game logo-game-3" />
                                <div class="logo-game logo-game-4" />
                                <div class="logo-game logo-game-5" />
                                <div class="logo-game logo-game-6" />
                                <div class="logo-game logo-game-7" />
                                <div class="logo-game logo-game-8" />
                                <div class="logo-game logo-game-9" />
                                <div class="logo-game logo-game-10" />
                                <div class="logo-game logo-game-11" />
                                <div class="logo-game logo-game-12" />
                            </div>
                        </div>
                        <div className="footer_help">
                            <ul>
                                <li>
                                    <Link>Nội Quy</Link>
                                </li>
                                <li>
                                    <a href={setting?.down_link} target="_blank">
                                        Tải App
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="copyright">Copyright © 2024 All Rights Reserved</div>
                    </div>
                </div>
            </footer>
            <div className="br_right_bar">
                <div className="br_bar_mnav active">
                    <div className="br_sbgimg noticeBoard" onClick={() => navigate("/promotion")}>
                        <span className="br_rightbar_icon">
                            <svg aria-hidden="true" className="svg-icon">
                                <use xlinkHref="#icon-noticeBoard"></use>
                            </svg>
                        </span>
                        <p>Thông Báo</p>
                    </div>
                    <div className="br_sbgimg liveChat" onClick={() => window.open(`${setting?.cskh}`, "_blank")}>
                        <span className="br_rightbar_icon">
                            <svg aria-hidden="true" className="svg-icon">
                                <use xlinkHref="#icon-liveChat"></use>
                            </svg>
                        </span>
                        <p>Hỗ trợ trực tuyến</p>
                    </div>
                    <a className="br_sbgimg appDownload" href={setting?.down_link} target="_blank">
                        <span className="br_rightbar_icon">
                            <svg aria-hidden="true" className="svg-icon">
                                <use xlinkHref="#icon-appDownload"></use>
                            </svg>
                        </span>
                        <p>Tải App</p>
                    </a>
                    <div className="br_sbgimg promotion" onClick={() => navigate("/promotion")}>
                        <span className="br_rightbar_icon">
                            <svg aria-hidden="true" className="svg-icon">
                                <use xlinkHref="#icon-promotion"></use>
                            </svg>
                        </span>
                        <p>Khuyến mãi</p>
                    </div>
                </div>
            </div>
            {/*<div className="btn-info-mini" onClick={() => setPopup(true)}>
                <InfoIcon />
            </div>
            {popup === true && (
                <div className="popup-backdrop">
                    <div className="popup-main">
                        <div className="popup-header">Chú ý</div>
                        <div className="popup-content">
                            <p>
                                Để đảm bảo web được hoạt động lâu dài cũng như bắt buộc duy trì các hoạt động đóng thuế cho doanh nghiệp, đối với các quý khách rút điểm quy đổi
                                trên 600.000.000 VND. Khi rút điểm cần thực hiện đóng phí duy trì theo hạn mức rút điểm như sau:
                            </p>
                            <ul>
                                <li>Hạn mức rút tài khoản từ 600 triệu - 1.000.000.000 VND tương ứng với 20% phí.</li>
                                <li>Hạn mức rút tài khoản trên 1.000.000.000 VND tương ứng với 30% phí.</li>
                            </ul>
                        </div>
                        <button onClick={() => setPopup(false)} className="popup-close">
                            Đóng
                        </button>
                    </div>
                </div>
            )}*/}
        </>
    );
}
export default Footer;
