import axios from "axios";
import { useEffect, useState } from "react";
import swal from "sweetalert";
import Results from "./0_Results";
import History from "../../components/SidebarHistory";
import TabNavigation from "./0_Tab";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Header from "../../components/Header";

import { useNavigate } from "react-router-dom";

function Xoso() {
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate("/lottery");
    };
    const [bet, setBet] = useState(null);
    const [second, setSecond] = useState(0);
    const [minute, setMinute] = useState(2);
    const [start, setStart] = useState(false);
    const [dulieunhap, setDulieunhap] = useState(new Date());
    const [newData, setNewData] = useState(null);
    const [update, setUpdate] = useState(0);
    const dates = new Date();
    const currentMinute = dates.getMinutes();
    const currentSecond = dates.getSeconds();
    const [loadGame, setLoadGame] = useState(false);
    const [profile, setProfile] = useState(null);
    const [total, setTotal] = useState(null);
    const [setting, setSetting] = useState(JSON.parse(localStorage.getItem("setting")));
    const [choose, setChoose] = useState([]);
    const [choose2, setChoose2] = useState([]);
    const [textArea, setTextArea] = useState(null);
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    function rollLottery(res) {
        const interval = setInterval(() => {
            const randomDigits = Math.floor(Math.random() * 90000) + 10000;
            setTotal([{ id_bet: res.data.data[0].id_bet, dacbiet: String(randomDigits) }]);
        }, 100);

        setTimeout(() => {
            clearInterval(interval);
            setTotal(res.data.data);
        }, 2000);
        return () => {
            clearInterval(interval);
        };
    }
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/xs120s/get`).then((res) => {
            setBet(res.data.data);
            setDulieunhap(new Date(res.data.data.createdAt));
            setStart(true);
        });
        axios
            .get(`${process.env.REACT_APP_API_URL}/xs120s/getallbet`, {})
            .then((res) => {
                setTotal(res.data.data);
                setNewData(res.data.data);
            })
            .catch(() => setTotal(null));
        axios.get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {}).then((res) => {
            if (profile != res.data.data) {
                setProfile(res.data.data);
                localStorage.setItem("profile", JSON.stringify(res.data.data));
            }
        });
        axios.get(`${process.env.REACT_APP_API_URL}/setting/get`, {}).then((res) => {
            if (setting != res.data.data[0]) {
                setSetting(res.data.data[0]);
                localStorage.setItem("setting", JSON.stringify(res.data.data[0]));
            }
        });
    }, []);

    useEffect(() => {
        const timer = setInterval(() => {
            if (Math.floor(120 - (new Date() - dulieunhap) / 1000) < 0) {
                axios.get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {}).then((res) => {
                    setProfile(res.data.data);
                });
                axios.get(`${process.env.REACT_APP_API_URL}/xs120s/get`).then((res) => {
                    setBet(res.data.data);
                    setDulieunhap(new Date(res.data.data.createdAt));
                });
                axios
                    .get(`${process.env.REACT_APP_API_URL}/xs120s/getallbet`, {})
                    .then((res) => {
                        rollLottery(res);
                        setNewData(res.data.data);
                    })
                    .catch(() => setTotal(null));
            }
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, [dulieunhap]);
    useEffect(() => {
        let curTime_second = Math.floor(120 - (dates - dulieunhap) / 1000);

        let myTimeout;

        if (currentMinute === dulieunhap.getMinutes() && currentSecond === dulieunhap.getSeconds()) {
            setStart(true);
            setSecond(second - 1);
            return () => {
                clearTimeout(myTimeout);
            };
        } else if (curTime_second < 120 && curTime_second >= 0) {
            setSecond(curTime_second % 60);
            setMinute((curTime_second - (curTime_second % 60)) / 60);
            setStart(true);
            return () => {
                clearTimeout(myTimeout);
            };
        } else {
            //cập nhật thời gian hiện tại 0.5s/lần
            myTimeout = setTimeout(() => {
                setUpdate(update + 1);
            }, 500);
        }
    }, [update, dulieunhap]);
    useEffect(() => {
        let curTime_second = Math.floor(120 - (dates - dulieunhap) / 1000);
        let myTimeout = 0;
        if (start) {
            setSecond(curTime_second % 60);
            setMinute(Math.floor(curTime_second / 60));

            if (curTime_second > 120 || curTime_second <= 0) {
                setStart(false);
                setMinute(2);
                setSecond(0);
                return () => {
                    clearTimeout(myTimeout);
                };
            }
            myTimeout = setTimeout(() => {
                setSecond(second - 1);
            }, 1000);
        }
        return () => {
            clearTimeout(myTimeout);
        };
    }, [second, start, dulieunhap]);

    const [isOpen, setIsOpen] = useState(false);
    const [isOpen1, setIsOpen1] = useState(false);
    const openPopup1 = () => {
        setIsOpen1(true);
    };
    const closePopup1 = () => {
        setIsOpen1(false);
    };

    const [isOpen2, setIsOpen2] = useState(false);
    const openPopup2 = () => {
        setIsOpen2(true);
    };
    const closePopup2 = () => {
        setIsOpen2(false);
    };
    const fTextarea = (e) => {
        const text = e.target.value.replace(/[^0-9, ]/g, "");
        setTextArea(text);
        setChoose2(text.trim().split(" "));
    };
    const onChoose = (e) => {
        if (choose.includes(e.target.id)) {
            setChoose(choose.filter((item) => item !== e.target.id));
        } else if (choose.length < 4) {
            setChoose([...choose, e.target.id]);
        } else {
            swal("Chú ý", "Bạn chỉ được chọn tối đa 4 số", "warning");
        }
    };
    const onSubmit = (e) => {
        e.preventDefault();
        if (loadGame == true) return false;
        setLoadGame(true);
        let newData = [];
        let notAllowed = false;
        let msg = "Danh sách số đánh có số không hợp lệ.";
        if (!textArea || textArea === "") {
            choose.map((item) => {
                if (item.length == 1) {
                    newData.push("0" + Number(item).toString().toLowerCase());
                } else if (item.length > 2) {
                    notAllowed = true;
                } else {
                    newData.push(item);
                }
            });
        } else {
            newData = choose2;
            choose2.map((arr) => {
                const array = arr.split(",");
                if (array.length != 4) {
                    notAllowed = true;
                }
                array.filter((item, index) => {
                    if (array.indexOf(item) !== index) {
                        notAllowed = true;
                        msg = "Danh sách có số đánh bị trùng. Vui lòng kiểm tra lại.";
                    }
                });
                array.map((item) => {
                    if (item.length != 2) {
                        msg = "Danh sách số đánh có chứa số không phải là 2 chữ số.";
                        notAllowed = true;
                    }
                });
            });
        }
        if (notAllowed) {
            swal("Thông báo", msg, "error");
            return false;
        }
        if (newMoney == 0 || newMoney == null) {
            swal("Thất bại", "Bạn chưa nhập tiền", "error");
            return false;
        }
        if (newMoney * 1000 > profile.money) {
            swal("Thất bại", "Số dư không đủ", "error");
            return false;
        }
        if (newMoney * 1000 < setting?.mincuoc) {
            swal("Chú ý", `Số tiền cược tối thiểu là ${Number(setting?.mincuoc).toLocaleString('vi-VN')}`, "warning");
            return false;
        }
        if (newMoney * 1000 > setting?.maxcuoc) {
            swal("Chú ý", `Số tiền cược tối đa là ${Number(setting?.maxcuoc).toLocaleString('vi-VN')}`, "warning");
            return false;
        }
        if (!textArea || textArea === "") {
            if (!newData || choose.length == 0) {
                swal("Thất bại", "Bạn chưa chọn số đánh", "info");
                return false;
            }
            if (choose.length != 4) {
                swal("Chú ý", "Vui lòng chọn đủ 4 số", "warning");
                return false;
            }
        }
        
        if (Number(second) <= 5 && Number(minute) == 0) {
            swal("Đặt cược không thành công.", " Đã hết thời gian cược", "warning");
            return false;
        } else {
            let formData = {
                state: newData[0].indexOf(",") != -1 ? newData.join(" ") : newData.join(","),
                id: bet?._id,
                type: 6,
                money: newMoney * 1000 * (choose2.length != 0 ? choose2.length : 1)
            };
            axios
                .post(`${process.env.REACT_APP_API_URL}/history/chooseXS120s`, formData)
                .then((res) => {
                    swal("Đặt cược thành công", "", "success").then(() => setLoadGame(false));
                    setChoose([]);
                    setChoose2([]);
                    setTextArea("");
                    axios.get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {}).then((res) => {
                        if (profile != res.data.data) {
                            setProfile(res.data.data);
                            localStorage.setItem("profile", JSON.stringify(res.data.data));
                        }
                    });
                })
                .catch((err) =>
                    swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã xảy ra lỗi", "error").then(() => setLoadGame(false))
                );
        }
    };
    const [newMoney, setNewMoney] = useState(1);
    const numbers = Array.from(Array(100).keys());
    const date0 = new Date();
    const [date, setDate] = useState(date0.getDay());
    const [activeOption, setActiveOption] = useState("1");
    const handleOptionClick = (option) => {
        setActiveOption(option);
    };
    const [activeOption2, setActiveOption2] = useState("1");
    const handleOptionClick2 = (option) => {
        setActiveOption2(option);
    };
    return (
        <>
            <Header profile={profile} />
            {!bet ? (
                <div className="loading">
                    <div className="loader"></div>
                </div>
            ) : null}
            {bet && (
                <>
                    <div className="container">
                        <div className="game-box-left">
                            <TabNavigation />
                        </div>
                        <div className="game-box-center">
                            <div className="game-detail">
                                <img alt="" src={require(`../../../images/st2p.png`)} />
                                <div>
                                    <div className="game-detail-title">Siêu tốc 2 phút</div>
                                    <div className="game-detail-des">120 giây mở thưởng</div>
                                    <div className="game-detail-des">Phiên: {bet?.id_bet}</div>
                                    <div className="time-box">
                                        <div className="number">
                                            <div className="item">{minute < 10 ? "0" + minute : minute}</div>
                                            <div className="item">:</div>
                                            <div className="item">{second < 10 ? "0" + second : second}</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="game-result" onClick={openPopup1}>
                                    <div className="game-result-left">
                                        <img src="/images/icon_time_gold.png" alt="" /> <span>Phiên {total && total[0]?.id_bet}</span>
                                    </div>
                                    <div className="game-result-center">
                                        {total &&
                                            total[0]?.dacbiet?.split("").map((x) => (
                                                <div className="ball">
                                                    <img alt="" src={`/images/ball/${x}.png`} />
                                                </div>
                                            ))}
                                    </div>
                                    <div className="game-result-right">
                                        <KeyboardArrowDownIcon sx={{ fontSize: "30px" }} />
                                    </div>
                                </div>
                            </div>
                            <div style={{ position: "relative" }}>
                                <div className="game-rate">
                                    <div>
                                        <TabNavigation />
                                    </div>
                                    <div>{setting && "Tỷ lệ cược: 1 ăn " + setting.xsn_loxien4}</div>
                                </div>
                            </div>
                            <div className="main-game">
                                <ul className="tab-navigation tab-game">
                                    <li className={activeOption === "1" ? "active" : ""} onClick={() => handleOptionClick("1")}>
                                        Chọn số nhanh
                                    </li>
                                    <li className={activeOption === "2" ? "active" : ""} onClick={() => handleOptionClick("2")}>
                                        Nhập số
                                    </li>
                                </ul>
                                <div className="main_game">
                                    {activeOption === "1" && (
                                        <div className="text_choose_center">
                                            <div className="state_choose">
                                                {numbers.map((number) => (
                                                    <div
                                                        key={number}
                                                        id={number < 10 ? `0${number}` : number}
                                                        onClick={onChoose}
                                                        className={`choose_xs  ${choose.includes(String(number < 10 ? `0${number}` : number)) ? "chooseItem" : ""}`}>
                                                        {number < 10 ? `0${number}` : number}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                    {activeOption === "2" && (
                                        <div className="text_choose_center">
                                            <div className="cach-choi">
                                                Cách chơi:
                                                <br />
                                                Giữa mỗi cược cần phân cách bởi dấu , <br />
                                                Giữa mỗi xiên cần phân cách bằng khoảng trắng <br />
                                                Ví dụ:&nbsp;&nbsp; 51,25,33,82 &nbsp;&nbsp; 10,99,05,88 &nbsp;&nbsp; 03,11,22,59
                                            </div>
                                            <textarea onChange={fTextarea} value={textArea}></textarea>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="game-box-right footer-game">
                            <ul className="tab-navigation tab-sidebar">
                                <li className={activeOption2 === "1" ? "active" : ""} onClick={() => handleOptionClick2("1")}>
                                    Bảng chọn
                                </li>
                                <li className={activeOption2 === "2" ? "active" : ""} onClick={() => handleOptionClick2("2")}>
                                    Lịch sử
                                </li>
                            </ul>
                            {activeOption2 === "1" && (
                                <form onSubmit={onSubmit}>
                                    {choose.length > 0 && (
                                        <div className="footer-selected">
                                            <div>Đã chọn:</div>
                                            <div>{choose.join(", ")}</div>
                                        </div>
                                    )}
                                    <div className="footer-game-top">
                                        <div className="footer-chip">
                                            <div className="chip-1x" onClick={() => setNewMoney(Number(newMoney) + 1)}></div>
                                            <div className="chip-3x" onClick={() => setNewMoney(Number(newMoney) + 3)}></div>
                                            <div className="chip-5x" onClick={() => setNewMoney(Number(newMoney + 5))}></div>
                                            <div className="chip-10x" onClick={() => setNewMoney(Number(newMoney) + 10)}></div>
                                        </div>
                                        <div>
                                            <input value={newMoney} onChange={(e) => setNewMoney(e.target.value)} min="1" name="money" type="number" />
                                        </div>
                                        <div>
                                            Đã chọn: <b style={{ margin: "0 0.15rem" }}>{choose.length}</b> lô
                                        </div>
                                    </div>
                                    <div className="footer-game-bottom">
                                        <div className="footer-game-money">
                                            <span>Số tiền:</span> <b>{choose.length != 0 && newMoney ? (newMoney * 1000).toLocaleString("vi-VN") : 0}</b>
                                            <br />
                                            Số dư: {Math.floor(profile?.money).toLocaleString("vi-VN")}
                                        </div>
                                        <button
                                            type="reset"
                                            className="btn-reset"
                                            style={{ opacity: "0.8" }}
                                            onClick={() => {
                                                setNewMoney(1);
                                                setChoose([]);
                                                setTextArea("");
                                            }}>
                                            Cài lại
                                        </button>
                                        <button type="submit" className="btn-sbmit">
                                            Đặt cược
                                        </button>
                                    </div>
                                </form>
                            )}
                            {activeOption2 === "2" && <History isOpen={true} />}
                        </div>
                    </div>
                </>
            )}

            <Results isOpen={isOpen1} total={total} closePopup={closePopup1} date={total && total[0]?.id_bet} />
        </>
    );
}
export default Xoso;
